<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:51:40
 * @LastEditTime: 2022-09-26 10:51:59
 * @Description: 已接受工单-详情
-->
<template>
  <div>已接受工单详情</div>
</template>

<script>
export default {};
</script>

<style>

</style>